import React from "react"
import Ample from "../components/ample/ample"
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

const NotFoundPage = (props) => {
  return (
    <Ample currPageTitle="Page not Found" location={props.location.href}>
      <div className="row">
          <div className="col-md-12">
              <div className="white-box">
                  <Alert severity="warning">
                    <AlertTitle>Page not Found</AlertTitle>
                    Page not Found
                  </Alert>
              </div>
          </div>
          </div>
    </Ample>);
};
export default NotFoundPage;